<template>
  <div
    v-if="event"
  >
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      v-b-tooltip.hover
      title="Modifier"
      variant="flat-warning"
      class="btn-icon rounded-circle"
    >
      <b-link
        v-b-modal="`modal-edit-event-${event.id}`"
      >
        <feather-icon
          icon="SettingsIcon"
          size="20"
        />
      </b-link>
    </b-button>
    <b-modal
      :id="`modal-edit-event-${event.id}`"
      cancel-variant="outline-primary"
      ok-title="Modifier"
      cancel-title="Annuler"
      centered
      title="Modifer l'évènement"
      @ok="updateEvent(event)"
      @cancel="resetEvent"
    >
      <b-form
        @submit.prevent
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Titre"
              label-for="h-title"
              label-cols-md="4"
            >
              <b-form-select
                v-model="event.title"
                name="h-title"
                :options="titleOptions"
                required
                :disabled="event.title === 'SAV' ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="event.title === 'SAV'"
            cols="12"
          >
            <b-form-group
              label="Device"
              label-for="h-device"
              label-cols-md="4"
            >
              <b-form-select
                v-model="event.deviceId"
                name="h-device"
                :options="deviceOptions"
                text-field="name"
                value-field="id"
                required
                :disabled="event.title === 'SAV' ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Début"
              label-for="h-startDate"
              label-cols-md="4"
            >
              <b-form-datepicker
                id="h-startDate"
                v-model="event.startDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="Début"
                hide-header
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Fin"
              label-for="h-endDate"
              label-cols-md="4"
            >
              <b-form-datepicker
                id="h-endDate"
                v-model="event.endDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="Fin"
                hide-header
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="État"
              label-for="h-state"
              label-cols-md="4"
            >
              <b-form-select
                v-model="event.state"
                name="h-state"
                :options="stateOptions"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Personne en charge"
              label-for="h-person"
              label-cols-md="4"
            >
              <v-select
                v-model="event.personInCharge"
                :options="personOptions.filter(onlyUnique).sort()"
                taggable
                push-tags
                label="Personne en charge"
              >
                <template #search="{attributes, events}">
                  <!-- eslint-disable-next-line -->
                  <input
                    class="vs__search"
                    :required="!event.personInCharge"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="event.title === 'SAV'"
            cols="12"
          >
            <b-form-group
              label="Gmax > 300G"
              label-for="h-boolData"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="event.boolData"
                name="h-boolData"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="event.title === 'Lugnium'"
            cols="12"
          >
            <b-form-group
              label="Hors Cadre"
              label-for="h-boolData"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="event.boolData"
                name="h-boolData"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Commentaire"
              label-for="h-comment"
              label-cols-md="4"
            >
              <b-form-textarea
                v-model="event.comment"
                name="h-comment"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label>Fichier à joindre (Autorise PDF, word, excel, images)</label>
            <b-form-file
              v-model="filesUploadTemp"
              accept=".jpg, .png, .pdf, .xls, .xlsx, .doc, .docx"
              placeholder="Choisir un fichier..."
              drop-placeholder="Déplacer un fichier ici..."
              multiple
              @input="uploadFile(filesUploadTemp, event.id)"
            />
          </b-col>
          <b-col
            cols="12"
          >
            <ul
              v-for="file, index in files"
              :key="index"
            >
              <li>
                {{ file.originalname ? file.originalname : file.name }}
                <feather-icon
                  v-if="file.originalname"
                  icon="XIcon"
                  size="18"
                  @click="removeFile(file.id)"
                />
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BForm, BFormGroup, BFormFile,
  BCol, BRow, BFormSelect, BLink, BFormTextarea,
  BButton, VBTooltip, BFormDatepicker, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormSelect,
    BLink,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormFile,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      deviceOptions: store.state.device.devicesList.sort((a, b) => a.name.localeCompare(b.name)),
      personOptions: ['aembarki', 'smarvie'],
      stateOptions: ['A faire', 'En cours', 'Terminé', 'Retard'],
      titleOptions: ['Lugnium', 'Étalonnage', 'SAV', 'Révision'],
      filesUploadTemp: [],
      formFile: null,
    }
  },
  methods: {
    async updateEvent(event) {
      await store.dispatch('event/updateEvent', { eventId: event.id, body: event })
        .then(async () => {
          if (this.formFile !== null) {
            await store.dispatch('file/addFile', this.formFile)
              .then(() => {
                this.formFile = null
              })
          }
        })
    },
    async uploadFile(filesUpload, eventId) {
      const data = new FormData()
      filesUpload.forEach(file => {
        this.files.push(file)
      })
      await filesUpload.forEach(file => {
        data.append('file', file)
        data.append('title', file.name)
        data.append('name', file.name)
        data.append('extension', file.type)
        data.append('mimetype', file.type)
        data.append('original_name', file.name)
        data.append('eventId', eventId)
      })
      this.formFile = data
    },
    removeFile(fileId) {
      store.dispatch('file/deleteFile', fileId)
        .then(() => {
          this.$router.go()
        })
    },
    resetEvent() {
      this.$router.go()
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
