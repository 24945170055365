<template>
  <div
    v-if="event"
    class="d-flex align-items-center"
  >
    <b-link
      v-b-modal="`modal-view-event-${event.id}`"
      class="font-weight-bold d-block link-hover"
    >
      <span v-if="event.title === 'SAV' && event.device">{{ event.device.enterprise }}</span>
      <span v-else>{{ event.title }}</span>
    </b-link>
    <b-button
      variant="flat-primary"
      class="btn-icon rounded-circle"
      disabled
    >
      <b-badge
        v-if="event.files && event.files.length > 0"
        v-b-tooltip.hover
        title="pièces jointes"
        variant="primary"
        pill
      >
        {{ event.files.length }}
      </b-badge>
    </b-button>
    <b-modal
      :id="`modal-view-event-${event.id}`"
      title="Évènement"
      ok-only
      centered
    >
      <b-row>
        <b-col cols="12">
          <p>Titre : {{ event.title }}</p>
        </b-col>
        <b-col
          v-if="event.title === 'SAV' && event.device"
          cols="12"
        >
          <p>Device : {{ event.device.name }}</p>
        </b-col>
        <b-col cols="12">
          <p>Début : {{ event.startDate | displayDate }}</p>
        </b-col>
        <b-col cols="12">
          <p>Fin : {{ event.endDate | displayDate }}</p>
        </b-col>
        <b-col cols="12">
          <p>État : {{ event.state }}</p>
        </b-col>
        <b-col cols="12">
          <p>Personne en charge : {{ event.personInCharge }}</p>
        </b-col>
        <b-col cols="12">
          <p v-if="event.title === 'SAV'">
            Gmax > 300G : {{ event.boolData ? 'Oui' : 'Non' }}
          </p>
          <p v-if="event.title === 'Lugnium'">
            Hors Cadre : {{ event.boolData ? 'Oui' : 'Non' }}
          </p>
        </b-col>
        <b-col cols="12">
          <p>Commentaire : {{ event.comment }}</p>
        </b-col>
        <b-col
          cols="12"
        >
          <h4 v-if="files">
            Pièces jointes
          </h4>
          <div
            v-for="file, indexFile in files"
            :key="indexFile"
          >
            <b-link
              class="data-orange"
              @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
            >
              {{ file.originalname }}
            </b-link>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BButton, BBadge,
  BCol, BRow, BLink,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BCol,
    BRow,
    BLink,
    BButton,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    files: {
      type: Array,
      default: null,
    },
  },
  methods: {
    async createAndDownloadBlobFile(body, filename, extension = 'png') {
      const result = await this.base64ToArrayBuffer(body)
      const blob = new Blob([result])
      const fileName = `${filename}.${extension}`
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64)
      const bytes = new Uint8Array(binaryString.length)
      return bytes.map((byte, i) => binaryString.charCodeAt(i))
    },
  },
}
</script>
