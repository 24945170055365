<template>
  <div>
    <b-card
      v-if="deviceData"
    >
      <b-img
        v-if="deviceData.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now()))"
        :src="require('@/assets/images/logo/decore-left.png')"
        class="img-lugnium"
      />
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="21"
          xl="7"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ deviceData.deviceType.name }} - {{ deviceData.name }}
                </h4>
              </div>
              <div
                v-if="$store.state.auth.role === 1"
                class="d-flex flex-wrap"
              >
                <b-button
                  v-if="deviceData.id"
                  :to="{ name: 'devices-update', params: { id: deviceData.id } }"
                  variant="primary"
                >
                  Modifier
                </b-button>
                <modal-new-event :device-id="deviceData.id" />
              </div>
            </div>
          </div>
          <div
            v-if="lastUpdate && lastUpdate.version"
            class="ml-1"
          >
            <p class="m-0">
              Version <span class="h4">{{ lastUpdate.version }}</span>
            </p>
          </div>

          <!-- User Stats -->
          <div class="d-flex align-items-center mt-1">
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="ActivityIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ deviceData.lastMetrology | displayDate }}
                </h5>
                <small>Dernier étalonnage</small>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-avatar
                variant="light-danger"
                rounded
              >
                <feather-icon
                  icon="ActivityIcon"
                  size="18"
                />
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ deviceData.nextMetrology | displayDate }}
                </h5>
                <small>Prochain étalonnage</small>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="5"
        >
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BoxIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Nom</span>
              </th>
              <td class="pb-50">
                {{ deviceData.name }} <span v-if="$store.state.auth.role === 1">(ID: {{ deviceData.id }})</span>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FilterIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Entreprise</span>
              </th>
              <td class="pb-50">
                {{ deviceData.enterprise }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Utilisateur</span>
              </th>
              <td class="pb-50">
                {{ deviceData.user_id }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Actif</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ deviceData.is_active ? "Oui" : "Non" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="InboxIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Type</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ deviceData.deviceType.name }}
              </td>
            </tr>
            <tr v-if="deviceData.headAssociate">
              <th class="pb-50">
                <feather-icon
                  icon="CpuIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Tête associée</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ deviceData.headAssociate }}
              </td>
            </tr>
            <tr v-if="deviceData.suitcaseAssociate">
              <th class="pb-50">
                <feather-icon
                  icon="BriefcaseIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Valise associée</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ deviceData.suitcaseAssociate }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="AwardIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Cofrac</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ deviceData.cofrac ? "Oui" : "Non" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ShieldIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Lugnium</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ deviceData.events.some(event => event.title === "Lugnium" && (Date.parse(event.endDate) > Date.now())) ? "Oui" : "Non" }} -
                {{ deviceData.events.some(event => event.title === "Lugnium" && event.boolData) ? "Hors cadre" : "" }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="TruckIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Location</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ deviceData.rent ? "Oui" : "Non" }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="deviceData && deviceData.events.length > 0 && $store.state.auth.role === 1"
      no-body
    >
      <b-card-header>
        <h5>Évènements</h5>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          @click="deploy('event')"
        >
          <feather-icon
            :icon="deployEvent ? 'ChevronDownIcon' : 'ChevronUpIcon'"
            size="20"
          />
        </b-button>
      </b-card-header>
      <b-table
        v-if="deviceData.events.length > 0"
        class="position-relative"
        :items="deviceData.events"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun évènement trouvé"
        :sort-desc.sync="isSortDirDesc"
        :style=" deployEvent ? 'display: none;' : ''"
      >

        <template #cell(id)="data">
          <div>
            <small>
              <span
                v-if="data.item.title === 'SAV'"
              >SI</span>
              <span
                v-else-if="data.item.title === 'Location'"
              >LOC</span>
              <span
                v-else
              >EVE</span>{{ data.item.id }}
            </small>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <modal-view-event
            :event="data.item"
            :files="data.item.files"
          />
        </template>

        <template #cell(startDate)="data">
          <div>
            <small>
              {{ data.item.startDate | displayDate }}
            </small>
          </div>
        </template>

        <template #cell(endDate)="data">
          <small>
            {{ data.item.endDate | displayDate }}
          </small>
        </template>

        <template #cell(state)="data">
          <b-badge
            pill
            :variant="`light-${resolveStateVariant(data.item.state)}`"
            class="text-capitalize d-flex justify-content-center"
          >
            {{ data.item.state }}
          </b-badge>
          <small
            v-if="data.item.boolData"
          >{{ data.item.boolData ? 'Hors cadre' : null }}</small>
        </template>

        <template #cell(comment)="data">
          <div>
            <small>
              {{ data.item.comment }}
            </small>
          </div>
        </template>

        <template #cell(personInCharge)="data">
          <div>
            <small>
              {{ data.item.personInCharge }}
            </small>
          </div>
        </template>

        <template #cell(action)="data">
          <div class="d-flex justify-content-end">
            <modal-edit-event
              :event.sync="data.item"
              :files.sync="data.item.files"
            />
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="deleteEvent(data.item)"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-card
      v-if="updatesList && updatesList.length > 0"
      no-body
    >
      <b-card-header>
        <h5>Updates</h5>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          @click="deploy('update')"
        >
          <feather-icon
            :icon="deployUpdate ? 'ChevronDownIcon' : 'ChevronUpIcon'"
            size="20"
          />
        </b-button>
      </b-card-header>
      <b-table
        v-if="updatesList.length > 0"
        class="position-relative"
        :items="updatesList"
        responsive
        :fields="tableColumnsUpdates"
        primary-key="id"
        show-empty
        empty-text="Aucun update trouvé"
        sort-by="download_completed"
        :sort-desc="true"
        :style=" deployUpdate ? 'height: 0px' : ''"
      >
        <template #cell(dockerSoftwareRelease_id)="data">
          <b-link
            class="font-weight-bold d-block link-hover"
          >
            {{ data.item.dockerSoftwareRelease_id }}
          </b-link>
        </template>

        <template #cell(download_started)="data">
          <div>
            <small>{{ data.item.download_started | displayDate }}</small>
          </div>
        </template>

        <template #cell(download_completed)="data">
          <div>
            <small>{{ data.item.download_completed | displayDate }}</small>
          </div>
        </template>

        <template #cell(user_id)="data">
          <div>
            <small>
              {{ data.item.user_id }}
            </small>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-card
      v-if="sitesList && sitesList.length > 0"
      no-body
    >
      <b-card-header>
        <h5>Sites</h5>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          @click="deploy('site')"
        >
          <feather-icon
            :icon="deploySite ? 'ChevronDownIcon' : 'ChevronUpIcon'"
            size="20"
          />
        </b-button>
      </b-card-header>
      <b-table
        v-if="sitesList.length > 0"
        class="position-relative"
        :items="sitesList.slice(
          (currentPage - 1) * perPage,
          currentPage * perPage,
        )"
        responsive
        :fields="tableColumnsSites"
        primary-key="id"
        show-empty
        empty-text="Aucun site trouvé"
        :sort-desc="true"
        :style=" deploySite ? 'display: none;' : ''"
      >
        <template #cell(updatedAt)="data">
          <b-link
            class="font-weight-bold d-block link-hover"
          >
            {{ data.item.mission }}
          </b-link>
          <small>{{ data.item.updatedAt | displayDate }}</small>
        </template>

        <template #cell(client)="data">
          <div>
            <small>
              {{ data.item.client }}
            </small>
          </div>
        </template>

        <template #cell(site)="data">
          <div>
            <small>
              {{ data.item.site }}
            </small>
          </div>
        </template>

        <template #cell(method)="data">
          <div>
            <small>
              {{ data.item.method }}
            </small>
          </div>
        </template>

        <template #cell(id)="data">
          <div>
            <b-link
              :href="`https://online.normenjeu.com/sites/${data.item.id}`"
              target="_blank"
            >
              go to online
            </b-link>
          </div>
        </template>

        <template #cell(user_id)="data">
          <div>
            <small>
              {{ data.item.user_id }}
            </small>
          </div>
        </template>
      </b-table>
      <div
        class="mx-2 mb-2 d-flex align-items-end justify-content-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="sitesList.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          :style=" deploySite ? 'display: none;' : ''"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
    <b-card
      v-if="manufacturingDetail"
      no-body
    >
      <manufacturing-view :manuf-data="manufacturingDetail" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BAvatar, BTable, BBadge,
  BLink, BCardHeader, BPagination, VBTooltip, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import ModalNewEvent from '../events/ModalNewEvent.vue'
import ModalEditEvent from '../events/ModalEditEvent.vue'
import ModalViewEvent from '../events/ModalViewEvent.vue'
import ManufacturingView from '../manufacturing/ManufacturingView.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    ModalNewEvent,
    ModalEditEvent,
    ModalViewEvent,
    BTable,
    BBadge,
    BLink,
    BCardHeader,
    BPagination,
    ManufacturingView,
    BImg,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      deviceData: null,
      tableColumns: [
        { key: 'id', label: 'N°', sortable: true },
        { key: 'createdAt', label: 'Titre', sortable: true },
        { key: 'startDate', label: 'Début', sortable: true },
        { key: 'endDate', label: 'Fin', sortable: true },
        {
          key: 'state', label: 'État', sortable: true, tdClass: 'text-center',
        },
        { key: 'comment', label: 'Commentaire', sortable: true },
        { key: 'personInCharge', label: 'Personne', sortable: false },
        { key: 'action', label: 'Actions', sortable: false },
      ],
      tableColumnsSites: [
        { key: 'updatedAt', label: 'Mission' },
        { key: 'client', label: 'Client' },
        { key: 'site', label: 'Site' },
        { key: 'method', label: 'Méthode' },
        { key: 'id', label: 'Online' },
        { key: 'user_id', label: 'User' },
      ],
      tableColumnsUpdates: [
        { key: 'dockerSoftwareRelease_id', label: 'Version', sortable: true },
        { key: 'download_started', label: 'Download started', sortable: true },
        { key: 'download_completed', label: 'Download completed', sortable: true },
        { key: 'user_id', label: 'Utilisateur', sortable: true },
      ],
      sortBy: 'createdAt',
      isSortDirDesc: true,
      stateOptions: ['A faire', 'En cours', 'Terminé', 'Retard'],
      titleOptions: ['Lugnium', 'Étalonnage'],
      sitesList: null,
      updatesList: null,
      manufacturingDetail: null,
      deploySite: true,
      deployEvent: true,
      deployUpdate: true,
      lastUpdate: {},
      allReleases: store.state.release.releasesList,
      lastRelease: null,
      // pagination
      perPage: 10,
      currentPage: 1,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.sitesList.length ? this.sitesList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      ).length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
      }
    },
    allUsers() {
      return store.state.user.usersList
    },
  },
  mounted() {
    this.getDevice()
  },
  methods: {
    getDevice() {
      store.dispatch('device/findDevice', this.$router.currentRoute.params.id)
        .then(response => {
          if (this.allUsers) {
            const [users] = this.allUsers.filter(user => user.id === response.user_id)
            if (response.user_id) {
              // eslint-disable-next-line no-param-reassign
              response.user_id = users.username
            }
          }
          this.deviceData = response
          if (this.deviceData.events && this.deviceData.events.length > 0) {
            this.deviceData.events.forEach(event => {
              this.$store.dispatch('file/fetchFile', { eventId: event.id })
                .then(res => {
                  if (res.length > 0) {
                    // eslint-disable-next-line no-param-reassign
                    event.files = []
                    res.forEach(file => {
                      const projectImage = Buffer.from(file.content).toString('base64')
                      // eslint-disable-next-line no-param-reassign
                      file.content = projectImage
                      // eslint-disable-next-line no-param-reassign
                      event.files.push(file)
                    })
                  }
                })
            })
          }
        })
        .then(() => this.getSites())
        .then(() => this.getUpdates())
        .then(() => this.getManufacturing(this.deviceData.name))
        .catch(err => {
          console.log('err', err)
          this.deviceData = null
        })
    },
    getSites() {
      store.dispatch('device/findSitesByDevice', this.$router.currentRoute.params.id)
        .then(response => {
          this.sitesList = response.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
          if (response.length > 0) {
            this.sitesList.map(site => {
              if (this.allUsers) {
                const [users] = this.allUsers.filter(user => user.id === site.user_id)
                if (site.user_id && users) {
                  // eslint-disable-next-line no-param-reassign
                  site.user_id = users.username
                }
              }
              return site
            })
          }
        })
    },
    getUpdates() {
      store.dispatch('device/findUpdatesByDevice', this.$router.currentRoute.params.id)
        .then(response => {
          if (response.length > 0) {
            this.updatesList = response.sort((a, b) => b.dockerSoftwareRelease_id - a.dockerSoftwareRelease_id)
            const [lastUpdate] = this.updatesList.filter(update => update.download_completed).sort((a, b) => b.download_completed.localeCompare(a.download_completed))
            this.lastUpdate = lastUpdate
            this.getLastRelease()
          }
        })
    },
    getManufacturing(deviceName) {
      store.dispatch('manufacturing/findManufacturingByDevice', deviceName)
        .then(response => {
          this.manufacturingDetail = response
        })
    },
    getLastRelease() {
      if (this.allReleases) {
        const [lastUpdate] = this.allReleases.filter(release => release.id === this.lastUpdate.dockerSoftwareRelease_id)
        this.lastUpdate.version = lastUpdate.version
        this.updatesList.map(update => {
          const [version] = this.allReleases.filter(release => release.id === update.dockerSoftwareRelease_id)
          // eslint-disable-next-line no-param-reassign
          update.dockerSoftwareRelease_id = version.version
          if (this.allUsers) {
            const [users] = this.allUsers.filter(user => user.id === update.user_id)
            if (users) {
              // eslint-disable-next-line no-param-reassign
              update.user_id = users.username
            } else {
              // eslint-disable-next-line no-param-reassign
              update.user_id = 'Administrateur'
            }
          }
          return update
        })
      }
    },
    // updateEvent(event) {
    //   store.dispatch('event/updateEvent', { eventId: event.id, body: event })
    //     .then(() => this.getDevice())
    // },
    deleteEvent(event) {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('event/deleteEvent', event.id)
            .then(() => {
              if (event.files && event.files.length > 0) {
                event.files.forEach(file => {
                  store.dispatch('file/deleteFile', file.id)
                })
              }
              this.$swal({
                icon: 'success',
                title: 'Supprimé!',
                text: 'L\'évènement a bien été supprimé',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
          this.getDevice()
          this.$router.go()
        }
      })
    },
    resolveStateVariant(data) {
      if (data === 'Terminé') return 'success'
      if (data === 'En cours') return 'info'
      if (data === 'Retard') return 'danger'
      if (data === 'A faire') return 'warning'
      return 'dark'
    },
    deploy(data) {
      if (data === 'site') {
        this.deploySite = !this.deploySite
      }
      if (data === 'event') {
        this.deployEvent = !this.deployEvent
      }
      if (data === 'update') {
        this.deployUpdate = !this.deployUpdate
      }
    },
  },
}
</script>

<style scoped>
.img-lugnium {
  width: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
