<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-create-event
      variant="outline-primary"
      class="ml-2"
    >
      <span v-if="deviceId">Ajouter un évenement</span>
      <span v-else>Ajouter</span>
    </b-button>
    <b-modal
      id="modal-create-event"
      cancel-variant="outline-primary"
      ok-title="Ajouter"
      cancel-title="Annuler"
      centered
      title="Nouvel évènement"
      @ok="addEvent(eventData, filesUploadTemp)"
    >
      <b-form
        @submit.prevent
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Titre"
              label-for="h-title"
              label-cols-md="4"
            >
              <b-form-select
                v-model="eventData.title"
                name="h-title"
                :options="titleOptions"
                required
                :disabled="deviceId ? false : true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!deviceId"
            cols="12"
          >
            <b-form-group
              label="Device"
              label-for="h-device"
              label-cols-md="4"
            >
              <b-form-select
                v-model="eventData.deviceId"
                name="h-device"
                :options="deviceOptions"
                text-field="name"
                value-field="id"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Début"
              label-for="h-startDate"
              label-cols-md="4"
            >
              <b-form-datepicker
                id="h-startDate"
                v-model="eventData.startDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                placeholder="Début"
                hide-header
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Fin"
              label-for="h-endDate"
              label-cols-md="4"
            >
              <b-form-datepicker
                id="h-endDate"
                v-model="eventData.endDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                type="date"
                placeholder="Fin"
                hide-header
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="État"
              label-for="h-state"
              label-cols-md="4"
            >
              <b-form-select
                v-model="eventData.state"
                name="h-state"
                :options="stateOptions"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Personne en charge"
              label-for="h-person"
              label-cols-md="4"
            >
              <v-select
                v-model="eventData.personInCharge"
                :options="personOptions.filter(onlyUnique).sort()"
                taggable
                push-tags
                label="Personne en charge"
              >
                <template #search="{attributes, events}">
                  <!-- eslint-disable-next-line -->
                  <input
                    class="vs__search"
                    :required="!eventData.personInCharge"
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="eventData.title === 'SAV'"
            cols="12"
          >
            <b-form-group
              label="Gmax > 300G"
              label-for="h-boolData"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="eventData.boolData"
                checked="false"
                name="h-boolData"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="eventData.title === 'Lugnium'"
            cols="12"
          >
            <b-form-group
              label="Hors Cadre"
              label-for="h-boolData"
              label-cols-md="4"
            >
              <b-form-checkbox
                v-model="eventData.boolData"
                checked="false"
                name="h-boolData"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Commentaire"
              label-for="h-comment"
              label-cols-md="4"
            >
              <b-form-textarea
                v-model="eventData.comment"
                name="h-comment"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label>Fichier à joindre (Autorise PDF, word, excel, images)</label>
            <b-form-file
              v-model="filesUploadTemp"
              accept=".jpg, .png, .pdf, .xls, .xlsx, .doc, .docx"
              placeholder="Choisir un fichier..."
              drop-placeholder="Déplacer un fichier ici..."
              multiple
            />
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormGroup, BFormCheckbox,
  BCol, BRow, BFormSelect, BFormTextarea, BFormDatepicker,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
/* eslint no-nested-ternary: 0 */
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BCol,
    BRow,
    BFormSelect,
    BFormTextarea,
    BFormDatepicker,
    BFormFile,
    vSelect,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    deviceId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      eventData: {
        title: this.deviceId ? null : 'SAV',
        startDate: null,
        endDate: null,
      },
      devicesList: store.state.device.devicesList.sort((a, b) => a.name.localeCompare(b.name)),
      personOptions: ['aembarki', 'smarvie'],
      stateOptions: ['A faire', 'En cours', 'Terminé', 'Retard'],
      titleOptions: ['Lugnium', 'Étalonnage', 'SAV', 'Révision'],
      filesUploadTemp: [],
      filesUploadList: [],
      formFile: null,
    }
  },
  computed: {
    deviceOptions() {
      return this.devicesList
    },
  },
  methods: {
    async addEvent(eventData, filesUploadTemp) {
      const eventDataFinal = eventData
      if (this.deviceId) {
        eventDataFinal.deviceId = this.deviceId
      }
      await store.dispatch('event/createEvent', eventDataFinal)
        .then(async event => {
          if (filesUploadTemp && filesUploadTemp.length > 0) {
            await this.uploadFile(filesUploadTemp, event.id)
            if (this.formFile !== null) {
              await store.dispatch('file/addFile', this.formFile)
            }
          }
          this.eventData = {
            title: this.deviceId ? null : 'SAV',
            startDate: null,
            endDate: null,
          }
          this.$router.go()
        })
    },
    async uploadFile(filesUpload, eventId) {
      const data = new FormData()
      filesUpload.forEach(file => {
        this.filesUploadList.push(file)
      })
      await filesUpload.forEach(file => {
        data.append('file', file)
        data.append('title', file.name)
        data.append('name', file.name)
        data.append('extension', file.type)
        data.append('mimetype', file.type)
        data.append('original_name', file.name)
        data.append('eventId', eventId)
      })
      this.formFile = data
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
