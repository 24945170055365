<template>
  <b-row
    v-if="processData"
  >
    <b-col>
      <b-card>
        <b-row>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column mb-2"
          >
            <h2>Processus de fabrication</h2>
            <div class="d-flex justify-content-start">
              <div class="d-flex flex-column ml-1">
                <h4 v-if="processData.deviceType">
                  {{ processData.deviceType.name }} - {{ processData.device_serialnumber }}
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    v-if="processData.id"
                    :to="{ name: 'manufacturings-edit', params: { id: processData.id } }"
                    variant="primary"
                  >
                    Modifier
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="prog-bar-ref"
          style="height: 100px;"
        >
          <b-col
            cols="12"
            class="prog-bar-1"
          >
            <b-progress
              :max="max"
            >
              <b-progress-bar
                :value="value"
              />
              <b-progress-bar
                :style="$store.state.appConfig.layout.skin === 'dark' ? 'background-color: #283047;' : 'background-color: white;'"
                :value="5 - value"
              />
            </b-progress>
          </b-col>
          <b-col
            cols="12"
            class="prog-bar-2"
          >
            <div class="d-flex justify-content-between w-100 mt-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 1 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(1)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="40"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 2 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(2)"
              >
                <feather-icon
                  size="40"
                  icon="ActivityIcon"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 3 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(3)"
              >
                <feather-icon
                  icon="BluetoothIcon"
                  size="40"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 4 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(4)"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="40"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 5 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(5)"
              >
                <feather-icon
                  icon="FilePlusIcon"
                  size="40"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 1"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 1
            </h2>
          </b-col>
          <b-col cols="12">
            <h4>
              Commande
            </h4>
            <p
              class="data-orange"
            >
              {{ processData.order }}
            </p>
          </b-col>
          <b-col md="3">
            <h4>
              Type
            </h4>
            <p
              v-if="processData.deviceType"
              class="data-orange"
            >
              {{ processData.deviceType.name }}
            </p>
          </b-col>
          <b-col md="3">
            <h4>
              N°Série du device
            </h4>
            <p class="data-orange">
              {{ processData.device_serialnumber }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <h4>
              N°Série de la tête
            </h4>
            <p class="data-orange">
              {{ processData.head_serialnumber }}
            </p>
          </b-col>
          <b-col md="3">
            <h4>
              N°Série de la valise
            </h4>
            <p class="data-orange">
              {{ processData.suitcase_serialnumber }}
            </p>
          </b-col>
          <b-col md="3">
            <h4>
              N°Série du lugdoswing
            </h4>
            <p class="data-orange">
              {{ processData.lugdoswing_serialnumber }}
            </p>
          </b-col>
          <b-col md="3">
            <h4>
              N°Série du bumper
            </h4>
            <p class="data-orange">
              {{ processData.bumper_serialnumber }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 2"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 2
            </h2>
          </b-col>
          <b-col cols="6">
            <h4>
              N° Accéléromètre X
            </h4>
            <p class="data-orange">
              {{ processData.accelXnumber }}
            </p>
          </b-col>
          <b-col cols="6">
            <h4>
              Sensibilité Accéléromètre X (mV/g)
            </h4>
            <p class="data-orange">
              {{ processData.accelXsens }}
            </p>
          </b-col>
          <b-col cols="6">
            <h4>
              N° Accéléromètre Y
            </h4>
            <p class="data-orange">
              {{ processData.accelYnumber }}
            </p>
          </b-col>
          <b-col cols="6">
            <h4>
              Sensibilité Accéléromètre Y (mV/g)
            </h4>
            <p class="data-orange">
              {{ processData.accelYsens }}
            </p>
          </b-col>
          <b-col cols="6">
            <h4>
              N° Accéléromètre Z
            </h4>
            <p class="data-orange">
              {{ processData.accelZnumber }}
            </p>
          </b-col>
          <b-col cols="6">
            <h4>
              Sensibilité Accéléromètre Z (mV/g)
            </h4>
            <p class="data-orange">
              {{ processData.accelZsens }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 3"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 3
            </h2>
          </b-col>
          <b-col cols="6">
            <h4>
              Mesure du 5 Volt
            </h4>
            <p class="data-orange">
              {{ processData.volt5Measure }}
            </p>
          </b-col>
          <b-col cols="6">
            <h4>
              N°Bluetooth
            </h4>
            <p class="data-orange">
              {{ processData.bluetoothNumber }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 4"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 4
            </h2>
          </b-col>
          <b-col cols="4">
            <h4>
              Client
            </h4>
            <p
              v-if="processData.user_id"
              class="data-orange"
            >
              {{ processData.user_id.enterprise }} - {{ processData.user_id.username }}
            </p>
          </b-col>
          <b-col cols="12">
            <h4>
              Test
            </h4>
            <p class="data-orange">
              {{ processData.test }}
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 5"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 5
            </h2>
          </b-col>
          <b-col
            cols="12"
            class="d-flex flex-column justify-content-center m-2"
          >
            <b-form-checkbox
              v-model="processData.extend_system"
              class="custom-control-primary"
              disabled
            >
              Étendue du système d'exploitation
            </b-form-checkbox>
            <b-form-checkbox
              v-model="processData.calibration_battery"
              class="custom-control-primary"
              disabled
            >
              Calibration de la batterie
            </b-form-checkbox>
            <b-form-checkbox
              v-model="processData.headValidation"
              class="custom-control-primary"
              disabled
            >
              Validation de la tête
            </b-form-checkbox>
            <b-form-checkbox
              v-model="processData.manufacturingValidation"
              class="custom-control-primary"
              disabled
            >
              Validation du processus de fabrication
            </b-form-checkbox>
          </b-col>
          <b-col
            v-if="files && files.length > 0"
            cols="12"
          >
            <h4>
              Pièces jointes
            </h4>
            <div
              v-for="file, indexFile in files"
              :key="indexFile"
            >
              <b-link
                class="data-orange"
                @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
              >
                {{ file.originalname }}
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BProgress, BProgressBar,
  BFormCheckbox, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BProgress,
    BProgressBar,
    BFormCheckbox,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    manufData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      processData: {},
      value: 0,
      max: 5,
      selectedStep: 1,
      files: [],
    }
  },
  mounted() {
    if (this.manufData) {
      this.processData = this.manufData
      if (this.processData.order && this.processData.device_typeId && this.processData.device_serialnumber && this.processData.head_serialnumber) {
        this.value = 1
      }
      if (this.value === 1 && this.processData.accelXnumber && this.processData.accelXsens && this.processData.accelYnumber && this.processData.accelYsens && this.processData.accelZnumber && this.processData.accelZsens) {
        this.value = 2
      }
      if (this.value === 2 && this.processData.volt5Measure && this.processData.bluetoothNumber) {
        this.value = 3
      }
      if (this.value === 3 && this.processData.user_id && this.processData.test) {
        this.value = 4
      }
      if (this.value === 4 && this.processData.headValidation && this.processData.manufacturingValidation) {
        this.value = 5
      }
      // hydrate client data
      if (store.state.user.usersList) {
        const [users] = store.state.user.usersList.filter(user => user.id === this.processData.user_id)
        // eslint-disable-next-line no-param-reassign
        this.processData.user_id = users
      }
      store.dispatch('file/fetchFile', { manufacturingId: this.manufData.id })
        .then(res => {
          if (res.length > 0) {
            res.forEach(file => {
              const projectImage = Buffer.from(file.content).toString('base64')
              file.content = projectImage
              this.files.push(file)
            })
          }
        })
    } else {
      store.dispatch('manufacturing/findManufacturing', this.$router.currentRoute.params.id)
        .then(response => {
          this.processData = response
          if (this.processData.order && this.processData.device_typeId && this.processData.device_serialnumber && this.processData.head_serialnumber) {
            this.value = 1
          }
          if (this.value === 1 && this.processData.accelXnumber && this.processData.accelXsens && this.processData.accelYnumber && this.processData.accelYsens && this.processData.accelZnumber && this.processData.accelZsens) {
            this.value = 2
          }
          if (this.value === 2 && this.processData.volt5Measure && this.processData.bluetoothNumber) {
            this.value = 3
          }
          if (this.value === 3 && this.processData.user_id && this.processData.test) {
            this.value = 4
          }
          if (this.value === 4 && this.processData.headValidation && this.processData.manufacturingValidation) {
            this.value = 5
          }
          // hydrate client data
          if (store.state.user.usersList) {
            const [users] = store.state.user.usersList.filter(user => user.id === this.processData.user_id)
            // eslint-disable-next-line no-param-reassign
            this.processData.user_id = users
          }
          store.dispatch('file/fetchFile', { manufacturingId: this.processData.id })
            .then(res => {
              if (res.length > 0) {
                res.forEach(file => {
                  const projectImage = Buffer.from(file.content).toString('base64')
                  file.content = projectImage
                  this.files.push(file)
                })
              }
            })
        })
    }
  },
  methods: {
    changeSelectedStep(step) {
      this.selectedStep = step
    },
    async createAndDownloadBlobFile(body, filename, extension = 'png') {
      const result = await this.base64ToArrayBuffer(body)
      const blob = new Blob([result])
      const fileName = `${filename}.${extension}`
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64)
      const bytes = new Uint8Array(binaryString.length)
      return bytes.map((byte, i) => binaryString.charCodeAt(i))
    },
  },
}
</script>

<style scoped>
.prog-bar-ref {
  position: relative;
}
.prog-bar-1 {
  position: absolute;
  top: 38px;
}
.prog-bar-2 {
  position: absolute;
  top:0;
}
.prog-bar-color {
  background-color: white;
}
.data-orange {
  font-weight: 600;
  color: #ff9100;
}
</style>
